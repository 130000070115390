import ReactDOM from 'react-dom/client'
import App from 'App'

import { getBaseURL, getSentryKey } from 'service/env'

import * as Sentry from '@sentry/react'

const isProd = process.env.REACT_APP_ENV === 'production'

Sentry.init({
  dsn: getSentryKey(),
  release: `${process.env.REACT_APP_PROJECT_NAME}@${process.env.REACT_APP_GIT_VERSION}`,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracePropagationTargets: [getBaseURL('dpoeasy'), getBaseURL('dponet')],
  tracesSampleRate: isProd ? 1.0 : 0,
  replaysSessionSampleRate: isProd ? 0.1 : 0,
  replaysOnErrorSampleRate: isProd ? 1.0 : 0,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App />)
