import { BaseUrlType } from 'types/auth.types'
import { ObjectOfString } from 'types/global.types'

const env: string = process.env.REACT_APP_API || 'development'

export const getBaseURL = (project: BaseUrlType) => envsBaseUrl[project][env]
export const getOctadeskUrl = () => octadeskUrl[env]
export const getGoogleRecaptchaToken = () => googleRecaptchaToken[env]
export const getPaymentAuthKey = () => paymentAuthKey[env]
export const getEditorTyneMceToken = () => editorTyneMceToken[env]
export const getMarketplaceToken = () => marketplaceToken[env]
export const getSentryKey = () => sentryKey[env]

type EnvsBaseUrlType = {
  [propKey: string]: ObjectOfString
}

const envsBaseUrl: EnvsBaseUrlType = {
  web: {
    development: 'http://localhost:3030',
    sandbox: 'https://easy.sandbox.dponet.com.br',
    production: 'https://easy.dponet.com.br',
  },
  dpoeasy: {
    development: 'http://api.localhost:3013',
    sandbox: 'https://cookies.sandbox.dponet.com.br',
    production: 'https://cookies.dponet.com.br',
  },
  dponet: {
    development: 'http://api.localhost:3001/lite',
    sandbox: 'https://api.sandbox.dponet.com.br/lite',
    production: 'https://api.dponet.com.br/lite',
  },
  marketplace: {
    development: 'http://api.backoffice.localhost:3012',
    sandbox: 'https://api.backoffice.parceiros.sandbox.dponet.com.br',
    production: 'https://api.backoffice.parceiros.dponet.com.br',
  },
  privacyPortal: {
    development: 'https://sandbox.privacidade.com.br',
    sandbox: 'https://sandbox.privacidade.com.br',
    production: 'https://privacidade.com.br',
  },
  dponetPortal: {
    development: 'http://localhost:3002',
    sandbox: 'https://app.sandbox.dponet.com.br',
    production: 'https://app.dponet.com.br',
  },
}

const googleRecaptchaToken: ObjectOfString = {
  development: '6LcUO9wlAAAAAFP4FAWvGoNeWOtbK-yCq40eKOvC',
  sandbox: '6Lfa-rgpAAAAAK94lnY0TKy9Kbv_1z8EwymjCcsC',
  production: '6LfkOdwlAAAAAKtuo2MBKEEFkn9BRr9wvEPRu_6Y',
}

const octadeskUrl: ObjectOfString = {
  development: 'https://dponet.octadesk.com/access/jwt',
  sandbox: 'https://dponet.octadesk.com/access/jwt',
  production: 'https://dponet.octadesk.com/access/jwt',
}

const editorTyneMceToken: ObjectOfString = {
  development: 'mhfqqnu8h9sdp9poi354uh2bqva111r9vm65bhe462kyux5i',
  sandbox: '04cwdxlxaqi28ar132hvaii2e6v6lmmk8z11ccd3nms3bscj',
  production: '04cwdxlxaqi28ar132hvaii2e6v6lmmk8z11ccd3nms3bscj',
}

const marketplaceToken: ObjectOfString = {
  development: 'd556bf32c2b53e1798e7a4f6a3b8226b',
  sandbox: 'd556bf32c2b53e1798e7a4f6a3b8226b',
  production: 'd556bf32c2b53e1798e7a4f6a3b8226b',
}

const paymentAuthKey: ObjectOfString = {
  development: 'afb075c950ffb53e02d1389083dadc50',
  sandbox: '36535fe3091f2523aae626fec5dfae68',
  production: 'e3fab64d276bf445fe69bc7ff1421f53',
}

const sentryKey: ObjectOfString = {
  development: '',
  sandbox: '',
  production:
    'https://8dceccb20dfb2950ca06875d8259990e@o4506756782620672.ingest.us.sentry.io/4508217292423168',
}
